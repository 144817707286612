import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { ElLoading } from 'element-plus';
import { getAccountList } from '@/service/index.js';
import { alertMessage } from '@/assets/js/tool.js';
import { setLocalStorage } from '@/assets/js/storage.js';
export default {
  setup() {
    //data
    const {
      t
    } = useI18n();
    const store = useStore();
    const addUserSuccess = computed(() => store.state.addUserSuccess);
    const list = ref([]);
    let loading = ElLoading.service({
      lock: true,
      background: 'rgba(0, 0, 0, 0)'
    });

    //watch
    watch(addUserSuccess, async () => {
      loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0)'
      });
      initAccountList();
    });
    initAccountList();

    //function
    /**初始化列表*/
    async function initAccountList() {
      let result = await getAccountList();
      loading.close();
      if (result.status === 0) {
        list.value = result.data || [];
      } else {
        if (result.status === -102) return;
        let text = result ? result.msg || t('failed.getData') : t('failed.getData');
        alertMessage('error', text);
      }
    }

    /**新建账号*/
    function addAccount() {
      store.commit('setRegisterIdentity', 2);
      store.commit('setShowRegister', true);
    }

    /**重置密码*/
    function resetPassword(email) {
      setLocalStorage('resetEmail', email);
      store.commit('setShowResetPwd', true);
    }
    return {
      list,
      //function
      initAccountList,
      addAccount,
      resetPassword
    };
  }
};